import React, { Component } from 'react';
import { PosterLoader, LoaderStyled } from './Loader.styled';

export default class Loader extends Component {
    render() {
        const poster = this.props.poster;
        if (poster) {
            return (
                <PosterLoader
                    className="h-100 w-100"
                    style={{
                        backgroundImage: `url(${poster})`,
                    }}
                />
            );
        }
        return (
            <div
                className={`position-relative overflow-hidden d-inline-block ${this.props.className || ''}`}
                style={{ width: this.props.size || 40, height: this.props.size || 40 }}
            >
                <LoaderStyled />
                <div
                    className="d-flex align-items-center justify-content-center position-absolute h-100 w-100 text-center small"
                    style={{ top: 0 }}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}
