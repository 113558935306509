import { notify } from '@bynder/design-system';
import { catchResponseError } from 'packages/helpers/helpers';
import store from './store';
import ProxyServerConnector from './services/connectors/ProxyServerConnector';
import authService from './services/authService';
import { getUser, logout, sendRefreshCommand } from './store/user/user.actions';
import AuthenticationHelper from './helpers/AuthenticationHelper';
import type { ResponseModel } from './services/connectors/ResponseModel';

const initServerConnector = (): void => {
    // ProxyServerConnector.setCsrfToken();

    ProxyServerConnector.getToken = (): string => AuthenticationHelper.getToken();

    ProxyServerConnector.tokenRefresher = async (requestObject: Request): Promise<any> => {
        const sendHangedRequest = (): Promise<ResponseModel> => {
            requestObject.headers.set('Authorization', `Bearer ${ProxyServerConnector.getToken()}`);

            return ProxyServerConnector.sendRequestObject(requestObject);
        };

        if (ProxyServerConnector.isBlocked) {
            await ProxyServerConnector.waitUntilUnblock();

            return sendHangedRequest();
        }

        try {
            const refreshTokenResponsePromise = authService.refreshToken();
            ProxyServerConnector.isBlocked = true;
            const refreshTokenResponse = await refreshTokenResponsePromise;

            if (refreshTokenResponse.status === 200) {
                await AuthenticationHelper.setToken(refreshTokenResponse.json.identityAccessToken);
                ProxyServerConnector.isBlocked = false;

                await store.dispatch(getUser());

                return sendHangedRequest();
            }

            if ([400, 404, 406].includes(refreshTokenResponse.status)) {
                store.dispatch(sendRefreshCommand());

                return new Promise((resolve) => {
                    ProxyServerConnector.requestsWaitList.push(async () => {
                        resolve(await sendHangedRequest());
                    });
                });
            }

            return ProxyServerConnector.handleResponseStatus(
                new Request('/auth/refresh'),
                new Response('', { status: 401 }),
            );
        } catch (err) {
            catchResponseError(err);
        } finally {
            ProxyServerConnector.isBlocked = false;
        }
    };

    window.addEventListener('onLogout', () => {
        store.dispatch(logout());
    });

    window.addEventListener('onForbidden', () => {
        window.location = '/no-access';
    });

    window.addEventListener('onNotFound', () => {
        window.location = '/not-found';
    });

    window.addEventListener('onInternalError', () => {
        notify({
            title: 'Something went wrong',
            variant: 'error',
        });
    });
    window.addEventListener('onFailedToFetch', () => {
        notify({
            title: 'Something went wrong',
            variant: 'error',
        });
    });
    window.addEventListener('onFailedToParseJson', () => {
        notify({
            title: 'Something went wrong',
            variant: 'error',
        });
    });
};

export default initServerConnector;
