import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import { TEMPLATES_PAGE_SIZE } from 'packages/pages/utils';
import * as types from '../types';

const initialState = {
    templates: [],
    systemTemplates: [],
    checked: new Set(),
    fetchOptions: {
        system: false,
        sort: ['date_created', 'desc'],
        page: 0,
        size: TEMPLATES_PAGE_SIZE,
        search: '',
        status: '',
        ownershipSource: OwnershipFilterType.OWNED_BY_ANYONE,
        collectionId: undefined,
        assignedToCollection: undefined,
    },
    searching: false,
    totalItems: 0,
    loadingData: false,
    newTemplateId: null,
    selectedTemplateId: -1,
    showAddModal: false,
    template: null,
    pendingTemplates: [],
    renameStatus: false,
};

export const TEMPLATES_PENDING_STATUSES = ['PROCESSING'];

export default function templatesReducer(state = initialState, action) {
    switch (action.type) {
        case types.INITIALIZE_TEMPLATES:
            return {
                ...initialState,
            };
        case types.TEMPLATES_RESET_OWNERSHIP:
            return {
                ...initialState,
                fetchOptions: {
                    ...initialState.fetchOptions,
                    ownershipSource: action.ownershipSource,
                },
            };
        case types.GET_TEMPLATES_REQUEST:
            return {
                ...state,
                loadingData: true,
                fetchOptions: {
                    ...state.fetchOptions,
                    ...action.options,
                    size: state.fetchOptions.size,
                },
                searching: action.searching,
            };
        case types.GET_SYSTEM_TEMPLATES_SUCCESS:
            return {
                ...state,
                systemTemplates: action.templates,
                selectedTemplateId: action.templates && action.templates.length > 0 ? action.templates[0].id : null,
            };
        case types.GET_TEMPLATES_SUCCESS: {
            const newChecked = new Set();

            if (!action.deselect) {
                action.templates.forEach((template) => {
                    if (state.checked.has(template.id)) {
                        newChecked.add(template.id);
                    }
                });
            }

            let selectedTemplateId = state.selectedTemplateId;

            if (action.setSelected) {
                selectedTemplateId = action.templates.length > 0 ? action.templates[0].id : null;
            }

            let templates = action.templates;

            const isSelectedTemplateMoved = selectedTemplateId in action.movedTemplates;
            const isSelectedTemplateInList = templates.some((template) => template.id === selectedTemplateId);

            if (isSelectedTemplateMoved && !isSelectedTemplateInList) {
                const selectedTemplate = state.templates.find((template) => template.id === selectedTemplateId);

                if (selectedTemplate) {
                    templates = [
                        {
                            ...selectedTemplate,
                            categoryId: action.movedTemplates[selectedTemplateId],
                            categoryName: undefined,
                        },
                        ...templates,
                    ];
                }
            }

            return {
                ...state,
                templates,
                checked: newChecked,
                totalItems: action.totalItems,
                loadingData: false,
                selectedTemplateId,
                pendingTemplates: action.templates
                    .filter((template) => TEMPLATES_PENDING_STATUSES.includes(template.thumbnailStatus))
                    .map((template) => template.id),
            };
        }
        case types.TEMPLATES_CHANGE_SOME: {
            const category = action.category;

            const templates = state.templates.map((template) => {
                const item = action.templateThumbnails.find(
                    (actionTemplate) => template.id === actionTemplate.creativeId,
                );

                if (!item) {
                    return template;
                }

                if (!item.thumbnailUrl) {
                    return template;
                }

                // Update category preview items if any
                if (category && category.previewItems.some((preview) => preview.creativeId === item.creativeId)) {
                    category.previewItems = category.previewItems.map((preview) => {
                        if (preview.creativeId === item.creativeId) {
                            return {
                                ...preview,
                                thumbnailUrl: item.thumbnailUrl,
                                thumbnailStatus: 'PROCESSED',
                            };
                        }

                        return preview;
                    });
                }

                return {
                    ...template,
                    checked: state.checked.has(template.id),
                    thumbnailImage: item.thumbnailUrl,
                    thumbnailStatus: 'PROCESSED',
                };
            });

            return {
                ...state,
                templates,
                pendingTemplates: templates
                    .filter((template) => TEMPLATES_PENDING_STATUSES.includes(template.thumbnailStatus))
                    .map((template) => template.id),
            };
        }
        case types.GET_TEMPLATES_FAIL:
            return {
                ...state,
                loadingData: false,
            };
        case types.SELECT_TEMPLATE: {
            const checkedClean = new Set();

            return {
                ...state,
                checked: checkedClean.add(action.templateId),
                selectedTemplateId: action.templateId,
            };
        }
        case types.TOGGLE_TEMPLATE: {
            const { checked: checkedOld } = state;

            const checked = new Set([...checkedOld]);

            if (checked.has(action.id)) {
                checked.delete(action.id);
            } else {
                checked.add(action.id);
            }

            return {
                ...state,
                checked,
            };
        }
        case types.TOGGLE_ALL_TEMPLATES:
            return {
                ...state,
                checked: action.check ? new Set([...state.templates.map((t) => t.id)]) : new Set(),
            };
        case types.SET_TEMPLATE:
            return {
                ...state,
                template: action.template,
            };
        case types.SET_RENAME_TEMPLATE_STATUS:
            return {
                ...state,
                renameStatus: action.status,
            };
        case types.UPDATE_TEMPLATES:
            return {
                ...state,
                templates: action.templates,
                renameStatus: false,
            };
        case types.RESET_FETCHED_TEMPLATES:
            return {
                ...state,
                templates: state.templates.filter((template) => (action.keep ?? []).includes(template.id)),
            };
        case types.DESELECT_TEMPLATE:
            return {
                ...state,
                checked: new Set(),
                selectedTemplateId: -1,
                template: null,
            };
        case types.DELETE_TEMPLATES_IN_PROGRESS:
            return {
                ...state,
                deleteInProgress: true,
            };
        case types.DELETE_TEMPLATES_SUCCESS:
            return {
                ...state,
                deleteInProgress: false,
            };
        case types.DELETE_TEMPLATES_FAIL:
            return {
                ...state,
                deleteInProgress: false,
            };
        default:
            return state;
    }
}
