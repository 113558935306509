import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';

export function PageWithAppcues(props: React.PropsWithChildren<{}>) {
    const location = useLocation();

    useEffect(() => {
        // @ts-ignore
        // eslint-disable-next-line no-undef
        if (APPCUES_ENABLED && window.Appcues && window.Appcues.page) {
            // @ts-ignore
            window.Appcues.page();
        }
    }, [location.pathname, location.search]);

    return props.children;
}

function RouteWithAppcues(props) {
    const location = useLocation();

    useEffect(() => {
        // @ts-ignore
        // eslint-disable-next-line no-undef
        if (APPCUES_ENABLED && window.Appcues && window.Appcues.page) {
            // @ts-ignore
            window.Appcues.page();
        }
    }, [location.pathname]);

    return <Route {...props} />;
}

export function sendAppcuesEvent(eventName: string, eventProperties: any) {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    if (APPCUES_ENABLED && window.Appcues && window.Appcues.track) {
        // @ts-ignore
        window.Appcues.track(eventName, eventProperties);
    }
}

export default RouteWithAppcues;
