import { addHours, isAfter } from 'date-fns';
import GroupsService from 'packages/services/GroupsService';
import FonstService from 'packages/services/FontsService';
import TrackingService from 'packages/services/TrackingService';
import SplitService from 'packages/services/SplitService';
import filtersStorage from 'packages/hooks/filtersStorage';
import { catchResponseError } from 'packages/helpers/helpers';
import { ActiveSplits } from 'packages/store/user/types';
import { sendAmplitudeUserData } from '~/store/amplitude/actions';
import roles from '~/configs/roles';
import * as types from '../types';
import UserService from '../../services/userService';
import AuthService from '../../services/authService';
import AuthorizationHelper from '../../helpers/AuthorizationHelper';
import isInIframe from '../../helpers/iframeHelper';
import ProxyServerConnector from '../../services/connectors/ProxyServerConnector';

export default {
    getUser,
    setDefaults,
};

const { getFiltersFromStorage, setFiltersToStorage } = filtersStorage();

export function fetchSplitFeature(feature) {
    return async (dispatch, getState) => {
        let { portalDomain, bynderAccessToken } = getState().platform;
        let timeout = 1000;

        while (!bynderAccessToken) {
            await new Promise((resolve) => setTimeout(resolve, timeout));
            timeout *= 2;
            const platform = getState().platform;

            portalDomain = platform.portalDomain;
            bynderAccessToken = platform.bynderAccessToken;
        }

        SplitService.retrieveFeatureTreatment({ portalDomain, bynderAccessToken }, feature)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: types.SET_SPLIT_FEATURES,
                        featureName: feature,
                        featureValue: response.json.treatment,
                    });

                    if (feature === ActiveSplits.DSBrandUpdate) {
                        try {
                            localStorage.setItem(`splitFeature-${feature}`, response.json.treatment);
                        } catch (err) {
                            console.error(err);
                        }
                    }
                }
            })
            .catch(catchResponseError);
    };
}

export function getUser() {
    return async (dispatch) => {
        try {
            const { status, json: data } = await UserService.getUser();

            if (status === 200 && data) {
                AuthorizationHelper.setPermissions(data.permissions, data.company);
                AuthorizationHelper.setRoles(data.roles);
                AuthorizationHelper.setFeatures(data.accessibleFeatures);

                const currentCompanyData = data.companies.find((company) => company.id === data.company);
                AuthorizationHelper.appcuesIdentify(data.user, currentCompanyData, data.roles, data.accessibleFeatures);

                dispatch(getUserFonts(data.company));

                dispatch({
                    type: types.LOGIN_SUCCESS,
                    user: data.user,
                    companies: data.companies,
                    currentCompany: data.company,
                    permissions: data.permissions,
                    roles: data.roles,
                    accessibleFeatures: data.accessibleFeatures,
                    bynderScopes: data.bynderScopes,
                });

                dispatch({
                    type: types.TOGGLE_APP_INITIALIZING,
                    state: false,
                });

                dispatch(sendAmplitudeUserData(data));

                const storedUser = getFiltersFromStorage('loginTracking');

                if (storedUser) {
                    if (isAfter(new Date(), addHours(new Date(storedUser.currentDateTime), 1))) {
                        await TrackingService.registerLogin({
                            bynderUserId: data.user.bynderUserId,
                            bynderAccountId: data.companies[0].bynderAccountId,
                        });

                        setFiltersToStorage('loginTracking', {
                            bynderUserId: data.user.bynderUserId,
                            bynderAccountId: data.companies[0].bynderAccountId,
                            currentDateTime: new Date(),
                        });
                    }
                } else {
                    await TrackingService.registerLogin({
                        bynderUserId: data.user.bynderUserId,
                        bynderAccountId: data.companies[0].bynderAccountId,
                    });

                    setFiltersToStorage('loginTracking', {
                        bynderUserId: data.user.bynderUserId,
                        bynderAccountId: data.companies[0].bynderAccountId,
                        currentDateTime: new Date(),
                    });
                }

                if (
                    !(
                        data.accessibleFeatures.includes('trial_enabled') &&
                        data.roles.includes(roles.video_brand_studio.video.manage)
                    )
                ) {
                    dispatch(getGroups());
                }
            }
        } catch (err) {
            catchResponseError(err);
        }
    };
}

export function loginBynderUser() {
    return (dispatch, getState) => {
        const { portalDomain, bynderAccessToken } = getState().platform;

        if (!bynderAccessToken) {
            console.error('loginBynderUser: No bynder access token.');

            return;
        }

        AuthService.exchangeBynderToIdentityToken({
            portalDomain,
            accessToken: bynderAccessToken,
        })
            .then((authResponse) => {
                if (authResponse.status === 200) {
                    if (authResponse.json.loginUrl) {
                        if (isInIframe()) {
                            dispatch(sendRedirectCommand(authResponse.json.loginUrl));
                        } else {
                            window.location.href = authResponse.json.loginUrl;
                        }

                        return null;
                    }

                    dispatch({
                        type: types.UPDATE_PLATFORM_OPTIONS,
                        options: {
                            accessToken: authResponse.json.identityAccessToken,
                        },
                    });

                    dispatch(getUser());

                    ProxyServerConnector.flushWaitList();
                }

                return null;
            })
            .then(() => {
                dispatch(fetchSplitFeature(ActiveSplits.DSBrandUpdate));
            })
            .catch(catchResponseError);
    };
}

export function registerBynderUser(authCode, state, cb) {
    return async (dispatch) => {
        dispatch({
            type: types.UPDATE_PLATFORM_OPTIONS,
            options: {
                bynderAccessToken: null,
            },
        });

        try {
            const {
                json: { bynderAccessToken },
                status,
            } = await AuthService.registerBynderUser({ authCode, state });

            if (status === 200) {
                dispatch({
                    type: types.UPDATE_PLATFORM_OPTIONS,
                    options: {
                        bynderAccessToken,
                    },
                });

                dispatch(fetchSplitFeature(ActiveSplits.DSBrandUpdate));

                if (cb) {
                    cb();
                }
            }
        } catch (err) {
            catchResponseError(err);
        }
    };
}

function getGroups() {
    return (dispatch) => {
        dispatch({ type: types.GET_GROUPS_REQUEST });
        GroupsService.getGroups()
            .then(({ json }) => {
                dispatch({ type: types.GET_GROUPS_SUCCESS, groups: json.items });
            })
            .catch(catchResponseError);
    };
}

function getUserFonts(currentCompanyId) {
    return (dispatch) => {
        dispatch({ type: types.GET_FONTS_REQUEST });

        FonstService.getFonts(currentCompanyId)
            .then(({ json }) => {
                dispatch({ type: types.GET_FONTS_SUCCESS, items: json });
            })
            .catch(catchResponseError);
    };
}

export function logout() {
    return (dispatch, getState) => {
        getState().platform.hostPort.postMessage({
            type: 'logout',
        });
        setFiltersToStorage('loginTracking', '');

        dispatch({ type: types.LOGOUT });
    };
}

export function sendRefreshCommand() {
    return (dispatch, getState) => {
        getState().platform.hostPort.postMessage({
            type: 'refresh_token',
        });
        setFiltersToStorage('loginTracking', {
            ...getFiltersFromStorage('loginTracking'),
            currentDateTime: new Date(),
        });
    };
}

function sendRedirectCommand(url) {
    return (dispatch, getState) => {
        getState().platform.hostPort.postMessage({
            type: 'redirect',
            url,
        });
    };
}

export function setDefaults() {
    return { type: types.USER_SET_DEFAULTS };
}

export function hideBackToOldMessage() {
    return { type: types.USER_BACK_TO_OLD };
}
