import styled, { keyframes } from 'styled-components';
import { token } from '@bynder/design-system';

const load8 = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const LoaderStyled = styled.div`
    border-radius: 50%;
    width: 100%;
    height: 100%;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 5px solid rgba(0, 170, 255, 0.2);
    border-right: 5px solid rgba(0, 170, 255, 0.2);
    border-bottom: 5px solid rgba(0, 170, 255, 0.2);
    border-left: 5px solid ${token.brandPrimary500};
    transform: translateZ(0);
    animation: ${load8} 1.1s infinite linear;

    &:after {
        border-radius: 50%;
        width: 100%;
        height: 100%;
    }
`;

export const PosterLoader = styled.div`
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
`;
