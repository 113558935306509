import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { setAutoFreeze } from 'immer';
import { DesignSystemProvider, token } from '@bynder/design-system';
import { LocalizationProvider } from '@bynder/localization';
import { createGlobalStyle } from 'styled-components';
import { initSentry } from './initSentry';

import HistoryListener from 'packages/pages/HistoryListener';
import store from '~/store';
import initServerConnector from '~/initServerConnector';
import { checkPlatformUsage } from '~/store/platform/platform.actions';
import fetchFallback from '~/helpers/localization';

import './styles/inter.css';
import './styles/index.css';
import 'requestidlecallback-polyfill';

const GlobalVideoStyle = createGlobalStyle`
    .video-js:hover .vjs-big-play-button,
    .video-js .vjs-big-play-button:focus,
    .video-js .vjs-big-play-button:active {
        background-color: ${token.brandPrimary500} !important;
    }

    .video-js .vjs-loading-spinner {
        border-color: ${token.brandPrimary500} !important;
    }

    .video-js .vjs-play-progress,
    .video-js .vjs-volume-level {
        background-color: ${token.brandPrimary500} !important;
    }
`;

if (window !== window.parent) {
    initSentry();
    store.dispatch(checkPlatformUsage());
    initServerConnector();
    setAutoFreeze(false);

    ReactDOM.createRoot(document.querySelector('#root')).render(
        <DesignSystemProvider>
            <GlobalVideoStyle />
            <LocalizationProvider fetchFallback={fetchFallback} project="creative-studio-frontend" forceFallback>
                <Provider key="provider" store={store}>
                    <Router>
                        <Suspense fallback={null}>
                            <Switch>
                                <Route path="/" component={HistoryListener} />
                            </Switch>
                        </Suspense>
                    </Router>
                </Provider>
            </LocalizationProvider>
        </DesignSystemProvider>,
    );
}
