import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import { GALLERY_PAGE_SIZE } from 'packages/pages/utils';
import * as types from '../types';

const initialState = {
    templates: [],
    systemTemplates: [],
    checked: new Set(),
    fetchOptions: {
        system: false,
        sort: ['date_created', 'desc'],
        page: 0,
        size: GALLERY_PAGE_SIZE,
        search: '',
        status: '',
        ownershipSource: OwnershipFilterType.OWNED_BY_ANYONE,
    },
    searching: false,
    totalItems: 0,
    loadingData: true,
    newTemplateId: null,
    selectedTemplateId: -1,
    showArchiveModal: false,
    showAddModal: false,
    template: null,
};

export default function galleryReducer(state = initialState, action) {
    switch (action.type) {
        case types.INITIALIZE_GALLERY:
            return {
                ...initialState,
            };
        case types.GALLERY_RESET_OWNERSHIP:
            return {
                ...initialState,
                fetchOptions: {
                    ...initialState.fetchOptions,
                    ownershipSource: action.ownershipSource,
                },
            };
        case types.GET_GALLERY_REQUEST:
            return {
                ...state,
                loadingData: true,
                fetchOptions: {
                    ...state.fetchOptions,
                    ...action.options,
                    size: state.fetchOptions.size,
                },
                searching: action.searching,
            };
        case types.GET_GALLERY_SUCCESS:
            const newChecked = new Set();
            !action.deselect &&
                action.templates.forEach((template) => {
                    if (state.checked.has(template.id)) newChecked.add(template.id);
                });
            return {
                ...state,
                templates: action.templates,
                checked: newChecked,
                totalItems: action.totalItems,
                loadingData: false,
                selectedTemplateId: action.setSelected
                    ? action.templates.length > 0
                        ? action.templates[0].id
                        : null
                    : state.selectedTemplateId,
            };
        case types.GET_GALLERY_FAIL:
            return {
                ...state,
                loadingData: false,
            };
        case types.SELECT_GALLERY_ITEM: {
            const checkedClean = new Set();

            return {
                ...state,
                checked: checkedClean.add(action.templateId),
                selectedTemplateId: action.templateId,
            };
        }
        case types.DESELECT_GALLERY_ITEM:
            return {
                ...state,
                checked: new Set(),
                selectedTemplateId: -1,
            };
        case types.RESET_FETCHED_GALLERY:
            return {
                ...state,
                templates: [],
            };
        default:
            return state;
    }
}
