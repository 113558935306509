import React, { Suspense, lazy, useEffect } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { lazyPrefetching } from 'packages/helpers/helpers';
import NotFound from 'packages/pages/errors/not-found';
import NoAccess from 'packages/pages/errors/no-access';
import PrivateRoute from 'packages/pages/PrivateRoute';
import { hostPortSelector } from '~/store/platform/platform.selectors';
import RouteWithAppcues from '~/helpers/RouteWithAppcues';

const App = lazy(
    () =>
        import(
            /* webpackChunkName: "app" */
            /* webpackPreload: true */
            'packages/pages/App'
        ),
);
const BynderAuth = lazyPrefetching(
    () =>
        import(
            /* webpackChunkName: "bynder.authorization" */
            /* webpackPrefetch: true */
            '~/pages/auth/index'
        ),
);

const HistoryListener = () => {
    const history = useHistory();
    const hostPort = useSelector(hostPortSelector);

    useEffect(() => {
        BynderAuth.prefetch();
    }, [hostPort?.postMessage]);

    useEffect(() => {
        let unlisten = () => {};

        if (hostPort) {
            let lastLocation = '';
            unlisten = history.listen((location) => {
                const newLocation = location.pathname + location.search;

                if (lastLocation && lastLocation === newLocation) {
                    return;
                }

                lastLocation = newLocation;

                hostPort.postMessage({
                    type: 'hash_update',
                    hash: newLocation,
                });
            });

            if (['/not-found', '/no-access'].includes(history.location.pathname)) {
                hostPort.postMessage({
                    type: 'hash_update',
                    hash: history.location.pathname,
                });
            }
        }
        return () => unlisten();
    }, [hostPort]);

    return (
        <Suspense fallback={null}>
            <Switch>
                <RouteWithAppcues path="/bynder-auth" component={BynderAuth} />
                <RouteWithAppcues path="/not-found" component={NotFound} />
                <RouteWithAppcues path="/no-access" component={NoAccess} />
                <PrivateRoute path="/" component={App} />
            </Switch>
        </Suspense>
    );
};

export default HistoryListener;
